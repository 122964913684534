<p-breadcrumb [model]="items$()">
    <ng-template pTemplate="item" let-item>
        @if (item.isLoading) {
            <p-skeleton styleClass="mb-2" width="100px"/>
        } @else {
            <span [ngClass]="breadcrumbsClass$()" >{{ item.label }}</span>
        }
    </ng-template>
    <ng-template pTemplate="separator"> 
        <span class="sl-typography-body-xxl">|</span>
    </ng-template>
</p-breadcrumb>
